<template>
  <div class="testConnection">
    <h1>Test Backend Connection</h1>
    <p>Check if this user can call the endpoints in the FireBase function `backend`.</p>
    <div v-if="error" class="error">There was an error <b>{{ error }}</b></div>
    <div v-if="isPending">Checking Permissions and route availability...</div>
    <form @submit.prevent>
      <el-row>
        <div class="submitButton">
          <el-button type="primary" round @click="testHello">Middleware OK?</el-button>
          <div v-if="helloSuccess">We got a reply: <b>{{ helloResponse.message }}</b></div>
        </div>
        <div class="submitButton">
          <el-button type="primary" round @click="testApiStatus">Node.js API OK?</el-button>
          <div v-if="apiStatusSuccess">We got a reply: <b>{{ apiResponse.response }}</b></div>
        </div>
        <div class="submitButton">
          <el-button type="primary" round @click="testDbConnection">Db Connection OK?</el-button>
          <div v-if="dbConnectionSuccess">We got a reply: <b>{{ dbConnectionResponse.response.message }}</b></div>
        </div>
        <div class="submitButton">
          <el-button type="warning" round @click="clearTests">Clear Test Results</el-button>
        </div>
      </el-row>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
// We need axios to make api calls
import { get, interceptors } from 'axios';
// Who is the currently logged in user
import { firebaseAuth } from '@/firebase/config';

export default {
  name: "Test Function Backend",
  components: {},
  setup() {
    // The API response is empty at first
    const helloResponse = ref([]);
    const apiResponse = ref([]);
    const dbConnectionResponse = ref([]);
    
    // Create a const to return errors if we have any
    const error = ref(null);

    // Track if we are currently waiting
    const isPending = ref(false);
    // Track if we have results from the function
    const gotResult = ref(false);
    // Track if we have results from the function
    const helloSuccess = ref(false);
    const apiStatusSuccess = ref(false);
    const dbConnectionSuccess = ref(false);

    // Function to reset the Test results
    const clearTests = () => {
      // Reset all the responses
      helloResponse.value = null;
      apiResponse.value = null;
      dbConnectionResponse.value = null;
      // Track if we have results from the function
      helloSuccess.value = false;
      apiStatusSuccess.value = false;
      dbConnectionSuccess.value = false;
      return;
    };
    
    // Function when we submit the request
    const testHello = async () => {
      // We are currently waiting
      isPending.value = true;
      
      // 
      // TODO: Refactor to get the information from the `getUser` composable
      const userToken = firebaseAuth.currentUser.accessToken;
      const userId = firebaseAuth.currentUser.uid;
      // log for debugging
      //    console.log("Current user Access token is: ",
      //        user
      //        );
      // Try to get the result from the function

      try {
        // Emtpy variables in case we call this again
        helloResponse.value = null;
        gotResult.value = false;
        helloSuccess.value = false;
        error.value = false;

        // Build the Call to the endpoint
        const apiPrefix = process.env.VUE_APP_API_FIREBASE_BACKEND;
        const endpoint = (apiPrefix +
            "/hello" +
            "?userId=" +
            userId);

        // Axios interceptor to add 
        //  - user credentials
        //  - FireBase User ID
        interceptors.request.use(
            function (req) {
                // log for debugging
//                console.log("Interceptor returns: ",
//                    "- Method: ", 
//                    req.method,
//                    "- url: ", 
//                    req.url
//                    );
                // Add the FireBase Token to the API request
                req.headers["Authorization"] = "Bearer " + userToken;
            return req;
        });

        // We start waiting
        isPending.value = true

        // debugger
        console.log("The endpoint is: ", endpoint);

        // Try to call the API
        const docRef = await get(endpoint);

        // Capture the result of the call we made
        //console.log("The response is: ", docRef.data);
        
        // We got a result:
        isPending.value = false;
        helloSuccess.value = true;

        // Update the error messages
        error.value = null;

        // Capture the result of the API call
        helloResponse.value = docRef.data;
    
        // log for debugging
        console.log("We got: ", helloResponse.value);

        return;
      }
      // If there is an error - catch it
      catch (err) {
        // We update the value of the error const
        error.value = err
        console.log("The error is: ", error.value);
        // We got a result:
        isPending.value = false;
        gotResult.value = true;
        helloSuccess.value = false;
        helloResponse.value = err.message;
      }
    }

    // Function when we submit the request
    const testApiStatus = async () => {
      // We are currently waiting
      isPending.value = true;
      
      // 
      // TODO: Refactor to get the information from the `getUser` composable
      const userToken = firebaseAuth.currentUser.accessToken;
      const userId = firebaseAuth.currentUser.uid;
      // log for debugging
      //    console.log("Current user Access token is: ",
      //        user
      //        );
      // Try to get the result from the function

      try {
        // Emtpy variables in case we call this again
        apiResponse.value = null;
        gotResult.value = false;
        apiStatusSuccess.value = false;
        error.value = false;

        // Build the Call to the endpoint
        const apiPrefix = process.env.VUE_APP_API_FIREBASE_BACKEND;
        const endpoint = (apiPrefix +
            "/status" +
            "?userId=" +
            userId);

        // Axios interceptor to add 
        //  - user credentials
        //  - FireBase User ID
        interceptors.request.use(
            function (req) {
                // log for debugging
//                console.log("Interceptor returns: ",
//                    "- Method: ", 
//                    req.method,
//                    "- url: ", 
//                    req.url
//                    );
                // Add the FireBase Token to the API request
                req.headers["Authorization"] = "Bearer " + userToken;
            return req;
        });

        // We start waiting
        isPending.value = true

        // debugger
        console.log("The endpoint is: ", endpoint);

        // Try to call the API
        const docRef = await get(endpoint);

        // Capture the result of the call we made
        //console.log("The response is: ", docRef.data);
        
        // We got a result:
        isPending.value = false;
        apiStatusSuccess.value = true;

        // Update the error messages
        error.value = null;

        // Capture the result of the API call
        apiResponse.value = docRef.data;
    
        // log for debugging
        console.log("We got: ", apiResponse.value);

        return;
      }
      // If there is an error - catch it
      catch (err) {
        // We update the value of the error const
        error.value = err
        console.log("The error is: ", error.value);
        // We got a result:
        isPending.value = false;
        gotResult.value = true;
        apiStatusSuccess.value = false;
        apiResponse.value = err.message;
      }
    }

    // Function when we submit the request
    const testDbConnection = async () => {
      // We are currently waiting
      isPending.value = true;
      
      // 
      // TODO: Refactor to get the information from the `getUser` composable
      const userToken = firebaseAuth.currentUser.accessToken;
      const userId = firebaseAuth.currentUser.uid;
      // log for debugging
      //    console.log("Current user Access token is: ",
      //        user
      //        );
      // Try to get the result from the function

      try {
        // Emtpy variables in case we call this again
        dbConnectionResponse.value = null;
        gotResult.value = false;
        dbConnectionSuccess.value = false;
        error.value = false;

        // Build the Call to the endpoint
        const apiPrefix = process.env.VUE_APP_API_FIREBASE_BACKEND;
        const endpoint = (apiPrefix +
            "/test-db-connection" +
            "?userId=" +
            userId);

        // Axios interceptor to add 
        //  - user credentials
        //  - FireBase User ID
        interceptors.request.use(
            function (req) {
                // log for debugging
                console.log("Interceptor returns: ",
                    "- Method: ", 
                    req.method,
                    "- url: ", 
                    req.url
                    );
                // Add the FireBase Token to the API request
                req.headers["Authorization"] = "Bearer " + userToken;
            return req;
        });

        // We start waiting
        isPending.value = true

        // debugger
        console.log("The endpoint is: ", endpoint);

        // Try to call the API
        const docRef = await get(endpoint);

        // Capture the result of the call we made
        //console.log("The response is: ", docRef.data);
        
        // We got a result:
        isPending.value = false;
        dbConnectionSuccess.value = true;

        // Update the error messages
        error.value = null;

        // Capture the result of the API call
        dbConnectionResponse.value = docRef.data;
    
        // log for debugging
        console.log("We got: ", dbConnectionResponse.value);

        return;
      }
      // If there is an error - catch it
      catch (err) {
        // We update the value of the error const
        error.value = err
        console.log("The error is: ", error.value);
        // We got a result:
        isPending.value = false;
        gotResult.value = true;
        dbConnectionSuccess.value = false;
        dbConnectionResponse.value = err.message;
      }
    } 

    // We need these in the template
    return { error, 
      isPending, 
      gotResult, 
      helloSuccess, 
      apiStatusSuccess, 
      dbConnectionSuccess, 
      testHello,
      testApiStatus,
      testDbConnection,
      helloResponse,
      apiResponse,
      dbConnectionResponse,
      clearTests,
    }
  }
};
</script>

<style>
  .logo {
    max-width: 420px;
    margin: 0px auto;
  }
  form {
    max-width: 420px;
    margin: 30px auto;
    background: white;
    text-align: left;
    padding: 40px;
    border-radius: 10px;
  }
  label {
    color: #aaa;
    display: inline-block;
    margin: 25px 0 15px;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
  }
  input {
    display: block;
    padding: 10px 6px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ddd;
    color: #555;
  }
  .submitButton {
    padding: 20px 6px;
    display: block;
    text-align: center;
  }
</style>
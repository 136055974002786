<template>
  <div class="generateAndActivate">
    <h1>Generate eCodes</h1>
    <p>Enter the Order Number to Generate eCodes using the generic format.</p>
    <div v-if="error" class="error">There was an error <b>{{ error }}</b></div>
    <div v-if="isPending">Generating eCodes...</div>
    <div v-if="gotResult">We got a result from the submission...</div>
    <div v-if="apiStatusSuccess">The API returned a success result...</div>
    <div v-if="apiStatusSuccess">Order Number: <b>{{ orderNumber }}</b></div>
    <div v-if="apiResponse">The API response was: <b>{{ apiResponse.response.message }}</b></div>
    <form @submit.prevent>
      <label>Order Number:</label>
      <input type="text" placeholder="Enter your Order Number" v-model="orderNumber" required>
      <div class="submitButton">
        <el-button type="primary" round @click="handleSubmit">Activate eCodes</el-button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
// We need axios to make api calls
import { get, interceptors } from 'axios';
// Who is the currently logged in user
import { firebaseAuth } from '@/firebase/config';

export default {
  name: "Generate Generic eCodes",
  components: {},
  setup() {
    // The orderNumber is empty at first
    const orderNumber = ref('');

    // The API response is empty at first
    const apiResponse = ref(null);

    // Create a const to return errors if we have any
    const error = ref(null);

    // Track if we are currently waiting
    const isPending = ref(false);

    // Track if we have results from the handleSubmit
    const gotResult = ref(false);

    // Track if we have results from the API call
    const apiStatusSuccess = ref(false);

    // Function when we submit the request
    const handleSubmit = async () => {

      // We are currently waiting
      isPending.value = true;

      // TODO: Refactor to get the information from the `getUser` composable
      const userToken = firebaseAuth.currentUser.accessToken;
      const userId = firebaseAuth.currentUser.uid;

      // Throw an error if the Order Number has the default value
      if (orderNumber.value == "") {
        // We can't get a result:
        isPending.value = false;
        gotResult.value = true;
        // We throw an error
        throw Error ("enter your orderNumber");
      } else {
        // We try to make the API call
        // Try to get the result from the API endpoint
        try {
          // Emtpy variables in case we call this again
          apiResponse.value = null;
          gotResult.value = false;
          apiStatusSuccess.value = false;
          error.value = false;

          // Build the Call to the endpoint
          const apiPrefix = process.env.VUE_APP_API_FIREBASE_BACKEND;
          const endpoint = (apiPrefix +
              "/generate" +
              "?userId=" +
              userId +
              "&orderNumber=" +
              orderNumber.value);

          // Axios interceptor to add 
          //  - user credentials
          //  - FireBase User ID
          interceptors.request.use(
            function (req) {
                // log for debugging
  //               console.log("Interceptor returns: ",
  //                   "- Method: ", 
  //                   req.method,
  //                   "- url: ", 
  //                   req.url
  //                   );
                // Add the FireBase Token to the API request
                req.headers["Authorization"] = "Bearer " + userToken;
            return req;
            }
          );

          // We start waiting
          isPending.value = true

          // debugger
          console.log("The endpoint is: ", endpoint);

          // Try to call the API
          const docRef = await get(endpoint);

          // Capture the result of the call we made
          console.log("The response is: ", docRef.data);
            
          // We got a result:
          isPending.value = false;
          gotResult.value = true;
          apiStatusSuccess.value = true;

          // Capture the result of the API call
          apiResponse.value = docRef.data;
  
          // log for debugging
          console.log("We got an API response: ", apiResponse.value);
        }
        // If there is an error - catch it
        catch (err) {
          // We update the value of the error const
          error.value = err
          console.log("Error trying to call the API: ", error.value);
          // We got a result:
          isPending.value = false;
          gotResult.value = true;
          apiStatusSuccess.value = false;
          apiResponse.value = err.message;
        }
      }
    }
    // Return what we need in the template
    return { orderNumber, 
      error, 
      isPending, 
      gotResult, 
      apiStatusSuccess,
      apiResponse,
      handleSubmit,
    }
  }
};
</script>

<style>
  .logo {
    max-width: 420px;
    margin: 0px auto;
  }
  form {
    max-width: 420px;
    margin: 30px auto;
    background: white;
    text-align: left;
    padding: 40px;
    border-radius: 10px;
  }
  label {
    color: #aaa;
    display: inline-block;
    margin: 25px 0 15px;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
  }
  input {
    display: block;
    padding: 10px 6px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ddd;
    color: #555;
  }
  .submitButton {
    padding: 20px 6px;
    display: block;
    text-align: center;
  }
</style>
import { ref } from 'vue';
// We are using the FireBase authentication
import { firebaseAuth } from "@/firebase/config";
// import the Firebase function to create user with email and password
import { createUserWithEmailAndPassword } from "firebase/auth";

// refs & signup outside of exported function
// they don't need to be re-created every time we invoke useSignup
const error = ref('');
// Set the initial value for this
const isPending = ref(false);

// eMail signup method:
// The get the Signup information from the user
const signup = async (email: string, password: string, displayName: string) => {
  // Reset error value if there was a previous error
  error.value = '';
  // We are waiting for an answer
  isPending.value = true;

  try {
    // Use the FireBase Authentication method to sign the user up
    // We use the `createUserWithEmailAndPassword`
    // We pass 3 objects:
    //  - `firebaseAuth` configuration object that allows us to access Firebase Auth on our project
    //  - `email`
    //  - `password`
    const res = await createUserWithEmailAndPassword(firebaseAuth, email, password);
    // If we have no response - Throw an error
    if (!res) {
      throw new Error('Could not complete signup');
    }
    // We record that there was no error
    error.value = '';
    // We wait for a reply from FireBase to update the user FireBase profile and add the Display name
    // TO DO - Add try /catch syntax here.
    //await res.user.updateProfile({ displayName });

    // We are no longer waiting
    isPending.value = false;
    
    // We capture the Response `res` we got from FireBase
    return res;
  }
  // If there is an error - Record the error message 
  catch(err: any) {
    // Display the error in the Console
    console.log(err.message);
    // We are no longer waiting
    isPending.value = false;
    // Update the error message that was set to NULL earlier
    error.value = err.message;
  }
}

// We record the Signup information for other components
const useSignup = () => {
  return { error, signup, isPending };
}

export default useSignup;
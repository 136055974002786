<template>
  <div class="home">
    <h1>Generate And Activate eCodes</h1>
    <p>This is where you can generate and Activate eCodes that can be used in the UNIQGIFT Exchange App</p>
    <div class="links">
      <div v-if="user">
        <router-link :to="{ name: 'Insert MOVE Vouchers' }">Upload</router-link> |
        <router-link :to="{ name: 'Generate And Activate Generic eCodes' }">Activate</router-link> | 
        <router-link :to="{ name: 'Download eCodes' }">Download</router-link> |
        <button @click="handleClick">Logout</button>
      </div>
      <div v-else>
        <router-link class="btn" :to="{ name: 'Login' }">Log In</router-link>
        <router-link class="btn" :to="{ name: 'Signup' }">Sign Up</router-link>
      </div>
    </div>
  </div>
</template>

<script>
// We need the `getUser` composable to check if user is logged in
import getUser from '@/composables/getUser'
// We need the `useLogout` composable
import useLogout from '@/composables/useLogout'
// We also need the `useRouter`
import { useRouter } from 'vue-router'

export default {
  setup() {
    // The `user` function uses the `getUser` composable
    const { user } = getUser()
    // The `logout` function uses the `useLogout` composable
    const { logout } = useLogout()
    // We need this to send the logged out user to the next page
    const router = useRouter()

    // The function when the user click `Logout`
    const handleClick = async () => {
      // We wait for a reply
      await logout()
      // We log that the user is logged out
      console.log('user has been logged out')
      // We redirect to the `Login` page
      router.push({ name: 'Login' })
    }

    // What the function returns
    return { handleClick, user }
  }
}
</script>


<style>

</style>

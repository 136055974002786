
import { ref } from 'vue'
// We also need the `useRouter`
import { useRouter } from 'vue-router'
// using @ means start at the project src root
import useLogin from '@/composables/useLogin'

export default {
  setup() {
    // We use the `useLogin` function
    const { error, thisLogin, isPending } = useLogin()

    // Set the email to empty at first
    const email = ref('')
    // Set the password to empty at first
    const password = ref('')

    // We need this to send the logged in user to the next page
    const router = useRouter()

    // Function associated to the Submit button
    const handleSubmit = async () => {
      // We are calling the login method 
      // passing the email and password that have been entered
      // Wait for the reply
      // eslint-disable-next-line
      const res = await thisLogin(email.value, password.value) 
      // If there is no error
      if (!error.value) {
        // In the Console, record that the user is logged in
        console.log("user logged in")
        // We redirect to the `Login` page
        router.push({ name: 'Home' })
      }
    }

    // This is what this function returns
    return { email, password, handleSubmit, error, isPending }
  }
}

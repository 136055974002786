import { ref } from "vue";
// We are using the FireBase authentication
import { firebaseAuth } from "@/firebase/config";
// The signIn function
import { signInWithEmailAndPassword  } from "firebase/auth";

// make sure the error is initially set to Null
const error = ref('')
// Set the initial value for this
const isPending = ref(false)

// login function with email and password
const thisLogin = async (email: string, password: string) => {
  // Reset error value if there was a previous error
  error.value = ''
  // We are waiting for an answer
  isPending.value = true

  try {
    // Try to sign the user in and record the info in the `res` constant
    const res = await signInWithEmailAndPassword(firebaseAuth, email, password)
    // If it goes through:
    // Recorde that there was no error
    error.value = ''
    // We are no longer waiting
    isPending.value = false
    // Return the login information
    return res
  }
  // If there is an error
  catch(err) {
    // Display the error in the Console
    console.log(err.message);
    // We are no longer waiting
    isPending.value = false;
    // Update the error message
    error.value = "We are not able to sign you in - Check your email and password";
  }
}

// We record the information for other components
const useLogin = () => {
  return { error, thisLogin, isPending }
}

export default useLogin
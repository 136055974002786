
// using @ means start at the project src root
import useSignup from '@/composables/useSignup'
import { ref } from 'vue'
// We also need the `useRouter`
import { useRouter } from 'vue-router'

export default {
  setup() {
    // We use the `useSignup` function
    const { error, signup, isPending } = useSignup()

    // Set the value to empty at first
    const email = ref('')
    // Set the value to empty at first
    const password = ref('')
    // Set the value to empty at first
    const displayName = ref('')

    // We need this to send the logged in user to the next page
    const router = useRouter()

    // Function associated to the Submit button
    const handleSubmit = async () => {
      // We are calling the login method 
      // passing the email and password that have been entered
      // Wait for the reply
      // eslint-disable-next-line
      const res = await signup(email.value, password.value, displayName.value)
      // If there is no error
      if (!error.value) {
        // In the Console, record that the user is signed up
        console.log('user signed up')
        // We redirect to the `Login` page
        router.push({ name: 'Home' })
      }
    }

    // This is what this function returns
    return { email, password, displayName, handleSubmit, error, isPending }
  }
}

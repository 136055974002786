import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// Generic Routes
import Home from "@/views/Home.vue";
import NotFound from "@/views/NotFound.vue";
import Signup from "@/views/auth/Signup.vue";
import Login from "@/views/auth/Login.vue";
import PendingAuthorisation from "@/views/auth/PendingAuthorisation.vue";

// Utilities and Tests Routes
import TestConnection from "@/views/utilities/TestConnection.vue";
import TestFunctionBackend from "@/views/utilities/TestFunctionBackend.vue";

// Standard eCodes Routes
import Upload from "@/views/generic/Upload.vue";
import InsertMoveVouchers from "@/views/generic/InsertMoveVouchers.vue";
import Generate from "@/views/generic/Generate.vue";
import GenerateAndActivate from "@/views/generic/GenerateAndActivate.vue";
import Download from "@/views/generic/Download.vue";

// To protect routes
import { firebaseAuth } from '@/firebase/config';
// TO DO - Add function to check authorisation

// We need the `functions` function with the correct firebase config info
import { functions } from "@/firebase/config";
// Needed to call Firebase callable functions
import { httpsCallable } from "firebase/functions";
import { ref } from 'vue';

// Function to protect the Protected routes
const requireAuth = (to:any, from:any, next:any) => {
  // Record the value for the current user
  const user = firebaseAuth.currentUser
  // If there is a current user
  if (user) {
    // process to the next page/route
    next()
  // If there is no current user
  } else {
    // go to the login page
    next({ name: 'Login' })
  }
}

// Function to protect the Authorized routes
const requirePermission = async (to:any, from:any, next:any) => {
  // Record the value for the current user
  const user = firebaseAuth.currentUser
  // Const isAuthorizedResult is false at first
  const isAuthorizedResult = ref(false);
  // If there is a current user
  if (user) {
    // We need to check if user is authorized
    const isAuthorized = httpsCallable(functions, "checkAuthorization");
    // We prepare the userId const
    const userId = user.uid;
    // We try to get result from the function
    try {
      // We can process to the next page/route
      console.log("User: ", userId, " is logged in, checking authorizations");
      // call the function and place the result in a const
      const result: any = await isAuthorized({
          userId: userId,
      });
      // log for debugging
      console.log("Result of authorization check is: ", result.data);
      console.log("Authorized? ", result.data.permissionCheck.isAuthorized);

      // Update the value of the result check
      isAuthorizedResult.value = result.data.permissionCheck.isAuthorized

      // If authorized - allow access
      if (isAuthorizedResult.value === true) {
        // Log for debugging:
        console.log("User is authorized to access");
        // process to the next page/route
        next();
      } else {
        // Log for debugging:
        console.log("User is NOT authorized to access");
        // Send the user to the `Pending` page.
        next({ name: 'Pending Authorisation' });
      }

    } catch (error) {
      // log for debugging
      console.log("There was and error while verifying the user permissions: ", error);
      // go to the Pending Authorization page
      next({ name: 'Pending Authorisation' })
    }
  // If there is no current user
  } else {
    // go to the login page
    next({ name: 'Login' })
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: requirePermission
  },
  {
    path: '/pending',
    name: 'Pending Authorisation',
    component: PendingAuthorisation,
    beforeEnter: requireAuth
  },
  {
    path: '/insert/move-vouchers',
    name: 'Insert MOVE Vouchers',
    component: InsertMoveVouchers,
    beforeEnter: requirePermission
  },
  {
    path: '/upload',
    name: 'Upload MOVE order',
    component: Upload,
    beforeEnter: requirePermission
  },
  {
    path: '/generate',
    name: 'Generate eCodes',
    component: Generate,
    beforeEnter: requirePermission
  },
  {
    path: '/generate-and-activate',
    name: 'Generate And Activate Generic eCodes',
    component: GenerateAndActivate,
    beforeEnter: requirePermission
  },
  {
    path: '/download',
    name: 'Download eCodes',
    component: Download,
    beforeEnter: requirePermission
  },
  // Utilities and Test Routes
  {
    path: '/test-connection',
    name: 'Test Connection',
    component: TestConnection,
    beforeEnter: requirePermission
  },
  {
    path: '/test-function-backend',
    name: 'Test Function Backend',
    component: TestFunctionBackend,
    beforeEnter: requirePermission
  },
  // Login and Signup
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  // 404 catchall
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
]

// The constant to know the URL for the route
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

import { ref } from 'vue';
// We are using the FireBase authentication
import { firebaseAuth } from '@/firebase/config';

// Set the user value to the user currently logged in
const user = ref(firebaseAuth.currentUser);

// Listen for change in the state of the current user information
firebaseAuth.onAuthStateChanged(_user => {
  // Record the information in the console
  console.log('User state change. Current user ID is:', _user);
  // Update the value of the user
  user.value = _user;
});

// What this function returns
const getUser = () => {
  return { user } 
}

export default getUser;
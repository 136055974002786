<template>
  <div class="testConnection">
    <h1>Test Backend Connection</h1>
    <p>Check if this user is allowed to call API in the Node backend.</p>
    <div v-if="error" class="error">There was an error <b>{{ error }}</b></div>
    <div v-if="isPending">Checking Permissions and route availability...</div>
    <form @submit.prevent>
      <div class="submitButton">
        <el-button type="primary" round @click="handleSubmitFunction">Test Connection via Function Call</el-button>
        <div v-if="gotFunctionResult">We got a reply from the Function: <b>{{ functionResult }}</b></div>
      </div>
      <div class="submitButton">
        <el-button type="primary" round @click="handleSubmitApi">Test Connection via Axios Call</el-button>
        <p>This should return a 403 Network error since we are not passing any API key here.</p>
        <div v-if="gotApiResult">We got a reply from the API: <b>{{ apiResponse }}</b></div>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
// We need the `functions` function with the correct firebase config info
import { functions } from "@/firebase/config";
// Needed to call Firebase callable functions
import { httpsCallable } from "firebase/functions";
// We need axios to make api calls
import { get } from 'axios';

export default {
  name: "Test Connection",
  components: {},
  setup() {
    // The result of the function is empty at first
    const functionResult = ref([]);
    // The API response is empty at first
    const apiResponse = ref([]);
    // Create a const to return errors if we have any
    const error = ref(null);
    // Track if we are currently waiting
    const isPending = ref(false);
    // Track if we have results from the function
    const gotApiResult = ref(false);
    // Track if we have results from the function
    const gotFunctionResult = ref(false);

    // Function when we submit the request
    const handleSubmitFunction = async () => {
      // We are currently waiting
      isPending.value = true;

      // Try to get the result from the function
      try {
        // We Try to get a reply from the funtion `checkConnection`
        const checkConnection = httpsCallable(functions, "checkConnection");

        // Emtpy variables in case we call this again
        functionResult.value = null;
        gotFunctionResult.value = false;
        error.value = false;

        // We start waiting
        isPending.value = true
    
        // Call the function that check the connection
        // This function does not take any parameter.
        const result = await checkConnection({});

        // log for debugging
        console.log("The reply from the call to the function is: ", result);

        // Capture the result of the call we made
        functionResult.value = result.data.message;
        // log for debugging
        console.log("The response from the function is: ", functionResult.value);
        
        // We got a result:
        isPending.value = false;
        gotFunctionResult.value = true;
        error.value = null;
      }
      // If there is an error - catch it
      catch (err) {
        // We update the value of the error const
        error.value = err.message
        console.log("The error is: ", error.value);
        // We got a result:
        isPending.value = false;
        gotFunctionResult.value = true;
        functionResult.value = err.message;
      }
    }

    // Function when we submit the request
    const handleSubmitApi = async () => {
      // We are currently waiting
      isPending.value = true;

      // Try to get the result from the function
      try {
        // Emtpy variables in case we call this again
        apiResponse.value = null;
        gotApiResult.value = false;
        error.value = null;

        // We start waiting
        isPending.value = true

        // Build the Call to the endpoint exposed by
        // the FireBase function `backend`
        const apiPrefix = process.env.VUE_APP_API_FIREBASE_BACKEND;
        const endpoint = (apiPrefix +
            "/status"
            );
    
        // log for debugging
        console.log("We are calling the API endpoint: ", endpoint);

        // Call the function that check the connection
        // This function does not take any parameter.
        const result = await get(endpoint);

        // log for debugging
        console.log("The reply from the call to the API endpoint is: ", result);

        // Capture the result of the call we made
        apiResponse.value = result;
        // log for debugging
        console.log("The response from the API: ", apiResponse.value);
        
        // We got a result:
        isPending.value = false;
        gotApiResult.value = true;
        error.value = '';

      }
      // If there is an error - catch it
      catch (err) {
        // We update the value of the error const
        error.value = err.message
        console.log("The error is: ", error.value);
        // We got a result:
        isPending.value = false;
        gotApiResult.value = true;
        apiResponse.value = err.message;
      }
    }

    // We need these in the template
    return { 
      error, 
      isPending, 
      gotApiResult, 
      gotFunctionResult, 
      handleSubmitFunction, 
      handleSubmitApi, 
      functionResult,
      apiResponse 
    }
  }
};
</script>

<style>
  .logo {
    max-width: 420px;
    margin: 0px auto;
  }
  form {
    max-width: 420px;
    margin: 30px auto;
    background: white;
    text-align: left;
    padding: 40px;
    border-radius: 10px;
  }
  label {
    color: #aaa;
    display: inline-block;
    margin: 25px 0 15px;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
  }
  input {
    display: block;
    padding: 10px 6px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ddd;
    color: #555;
  }
  .submitButton {
    padding: 20px 6px;
    display: block;
    text-align: center;
  }
</style>
<template>
    <div class="Insert">
        <div>
            <h1>Step 1- Add MOVE Vouchers</h1>
            <div v-if="isInitial">
                <p>Upload the content of csv file prepare from MOVE data into the `move_vouchers` table in the `voucher_vault
                database`.</p>
                <p>The file <b>MUST</b> be formatted as described in the page: 
                <a href="https://uniqgift.sharepoint.com/sites/VoucherApp/SitePages/Prepare-Import-File.aspx"
                    target="_blank">How to Prepare the import file (.csv)</a> (On UG SharePoint).</p>
            </div>
        </div>
        <div class="messages">
            <div v-if="success">
                <p>We tried to insert {{ (i) }} MOVE vouchers in the Voucher Vault.</p>
                <p><b>{{ iSuccess }} voucher(s)</b> correctly inserted</p>
                <p><b>{{ iFailed }} voucher(s)</b> could NOT be inserted</p>
            </div>
            <p v-if="error">There was an error.</p>
        </div>
        <form v-if="success" @submit.prevent>
            <div class="submitButton">
                <el-button type="primary" round @click="nextPage">Go To eCode Activation</el-button>
            </div>
            <div class="submitButton">
                <el-button type="primary" round @click="tryAgain">Try Again</el-button>
            </div>
        </form>
        <div v-if="isInitial" class="dropbox">
            <input ref="file" v-on:change="handleInsert()" type="file" accept=".csv" single class="input-file">
            <p>Drag your file here to begin<br> or click to browse</p>
            <p v-if="isPending">Processing file...</p>
        </div>
    </div>
</template>

<script>

import { ref } from "vue";
// Import the objects we need from papapares
import Papa from "papaparse";
// Import qs to stringify JSON results
import qs from "qs";
// Import axios to call API endpoints
import axios from 'axios';
// Who is the currently logged in user
import { firebaseAuth } from '@/firebase/config';
// We also need the `useRouter`
import { useRouter } from 'vue-router'

export default {
    name: 'Upload MOVE order file',
    setup() {
        // Create a const to return errors if we have any
        const error = ref(null);

        // file to use is empty at first
        const file = ref(null)

        // Track if we are in the initial state
        const isInitial = ref(true);

        // Track if we are currently waiting
        const isPending = ref(false);

        // Track if we have results from the function
        const success = ref(false);

        // Create a counter to capture which loop we are in
        const i = ref(0);
        // Create a counter to capture successful insert
        const iSuccess = ref(0);
        // Create a counter to capture failed insert
        const iFailed = ref(0);
        
        // We need this to send the user to the next page
        const router = useRouter();

        // Inner function to validate if a date is in the correct format
        // This function takes an input in the format `YYYY-MM-DD`
        //  - Check if the format is correct
        //  - Check if this is a valid date
        //  - return true or false.
        const dateIsValid = function (dateStr) {
            // We use REGEX to check the format of the dateString
            const regex = /^\d{4}-\d{2}-\d{2}$/;
            // Check if the format is correct
            if (dateStr.match(regex) === null) {
                // format is incorrect - return false
                return false;
            }
            // Prepare a const to check if this is a valid date.
            const date = new Date(dateStr);
            // Try to build a timestamp from the date const
            const timestamp = date.getTime();
            // Check if the timestamp is valid
            if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
                // Timestamp is not valid - this is not a date
                return false;
            }
            // return the provided sting as an ISO formatted date value.
            return date.toISOString().startsWith(dateStr);
        };

        // Function when we request next
        // We redirect to the `Activate` page
        const nextPage = () => {
            router.push({ name: 'Generate And Activate Generic eCodes' });
        };

        // Function when we request to upload another file
        // We flush all the variable to the initial values.
        const tryAgain = () => {
            error.value = null;
            file.value = null;
            isInitial.value = true;
            isPending.value = false;
            success.value = false;
            i.value = 0;
            iSuccess.value = 0;
            iFailed.value = 0;
        };

        const handleInsert = async () => {
            // Log for debugging;
            //console.log("selected file",
            //    file.value.files[0]
            //    );
            //Upload to server
            // We are currently waiting
            isPending.value = true;
            isInitial.value = false;

            // TODO: Refactor to get the information from the `getUser` composable
            const userToken = firebaseAuth.currentUser.accessToken;

            // Get the info about the selected file
            const fileSelected = file.value.files[0];

            // Log for debugging
            console.log("selected file",
                fileSelected
            );

            // Reset the counter
            i.value = 0;

            // Log for debugging
            //console.log("counter is set to: ",
            //    i.value
            //);

            // Create a const to check the value of the checks
            const checkRowResult = ref(false);

            // The validation function to check that the data are in the correct format
            // We use the variable `result` provided by the papaparse function.
            const checkRow = async function (result) {
                // Log for debugging
                //console.log("The row we have to check: ",
                //    result
                //    );
                // Check if we have an error
                if ( result.errors.length !== 0 ) {
                    // there is an error with this row.
                    // Log for debugging
                    console.log("The error we got for the row: ",
                        i.value, 
                        " is: ",
                        result.error
                        );
                    // Stop here
                    return false;
                } else {
                    // We have no error. Check the data
                    // Log for debugging
                    console.log("The data we got for row: ",
                        i.value, 
                        " is: ",
                        result.data
                    );
                    // Simplify syntax
                    const rowData = result.data;
                    // Explain what we are doing
                    console.log ("We are checking row: ",
                        i.value
                        );
                    // Check if we have all the columns we need:
                    if ((rowData.moveClientName == undefined) || (rowData.moveClientName == "")) {
                        // Log for debugging
                        console.log("Row: ",
                            i.value,
                            "moveClientName column MUST exist and cannot be empty"
                            );
                        // Stop here
                        return false;
                    }
                    if ((rowData.moveQuotationNumber == undefined) || (rowData.moveQuotationNumber == "")) {
                        // Log for debugging
                        console.log("Row: ",
                            i.value,
                            "moveQuotationNumber column MUST exist and cannot be empty"
                            );
                        // Stop here
                        return false;
                    }
                    if ((rowData.moveOrderNumber == undefined) || (rowData.moveOrderNumber == "")) {
                        // Log for debugging
                        console.log("Row: ",
                            i.value,
                            "moveOrderNumber column MUST exist and cannot be empty"
                            );
                        // Stop here
                        return false;
                    }
                    if ((rowData.moveProductCode == undefined) || (rowData.moveProductCode == "")) {
                        // Log for debugging
                        console.log("Row: ",
                            i.value,
                            "moveProductCode column MUST exist and cannot be empty"
                            );
                        // Stop here
                        return false;
                    }
                    if ((rowData.moveVoucherNumber == undefined) || (rowData.moveVoucherNumber == "")) {
                        // Log for debugging
                        console.log("Row: ",
                            i.value,
                            "moveVoucherNumber column MUST exist and cannot be empty"
                            );
                        // Stop here
                        return false;
                    }
                    if ((rowData.moveProductName == undefined) || (rowData.moveProductName == "")) {
                        // Log for debugging
                        console.log("Row: ",
                            i.value,
                            "moveProductName column MUST exist and cannot be empty"
                            );
                        // Stop here
                        return false;
                    }
                    if ((rowData.moveActiveDate == undefined) || (rowData.moveActiveDate == "")) {
                        // Log for debugging
                        console.log("Row: ",
                            i.value,
                            "moveActiveDate column MUST exist and cannot be empty"
                            );
                        // Stop here
                        return false;
                    }
                    if ( dateIsValid(rowData.moveActiveDate) == false ) {
                        // Log for debugging
                        console.log("Row: ",
                            i.value,
                            "moveActiveDate is not a valid date - check the fomat - format should be YYYY-MM-DD"
                            );
                        // Stop here
                        return false;
                    }
                    if ((rowData.moveExpiryDate == undefined) || (rowData.moveExpiryDate == "")) {
                        // Log for debugging
                        console.log("Row: ",
                            i.value,
                            "moveExpiryDate column MUST exist and cannot be empty"
                            );
                        // Stop here
                        return false;
                    }
                    if ( dateIsValid(rowData.moveExpiryDate) == false ) {
                        // Log for debugging
                        console.log("Row: ",
                            i.value,
                            "moveExpiryDate is not a valid date - check the fomat - format should be YYYY-MM-DD"
                            );
                        // Stop here
                        return false;
                    }
                    if ((rowData.moveVoucherGuid == undefined) || (rowData.moveVoucherGuid == "")) {
                        // Log for debugging
                        console.log("Row: ",
                            i.value,
                            "moveVoucherGuid column MUST exist and cannot be empty"
                            );
                        // Stop here
                        return false;
                    }
                    if ((rowData.moveShortUrl == undefined) || (rowData.moveShortUrl == "")) {
                        // Log for debugging
                        console.log("Row: ",
                            i.value,
                            "moveShortUrl column MUST exist and cannot be empty"
                            );
                        // Stop here
                        return false;
                    }
                    if ((rowData.moveAuthCode == undefined) || (rowData.moveAuthCode == "")) {
                        // Log for debugging
                        console.log("Row: ",
                            i.value,
                            "moveAuthCode column MUST exist and cannot be empty"
                            );
                        // Stop here
                        return false;
                    }
                    // Log for debugging
                    console.log("No errors reported while reading the row: ",
                        i.value
                        );
                    // Log for debugging
                    console.log("All checks passed successfully for row: ",
                        i.value
                        );
                    // Stop here
                    return true;
                }
            };

            // The `parser` function called by papaparse step.
            // This:
            //  - Tries to check the row
            //  - If it can't check the row
            //      - Increment the count of failed row.
            //      - log a message in the console.
            //      - Stop and wait for the next row
            //  - if row is not OK:
            //      - increment the count of failed row.
            //      - log a message in the console.
            //      - Stop and wait for the next row
            //  - if row is OK, 
            //      - try to write the record in the database
            //      - If it can't insert the row
            //          - Increment the count of failed row.
            //          - log a message in the console.
            //          - Stop and wait for the next row
            //      - If it CAN insert the row
            //          - Increment the count of success row.
            //          - log a message in the console.
            //          - Stop and wait for the next row
            const writeToVault = async function (result) {
                // Log for debugging
                //console.log("The data we got: ",
                //    result.data
                //);
                // Log for debugging
                console.log("We are trying to insert row: ",
                    i.value
                );

                // Try the checkRow function
                try {
                    // This will check if the row has all the info we need
                    // and update the result of the check
                    checkRowResult.value = await checkRow(result);
                    // Log for debugging:
                    console.log("The checkRowResult for the Row: ",
                        i.value,
                        " is: ",
                        checkRowResult.value
                        );
                } catch (err) {
                    // Log for debugging:
                    console.log("There was an error checking the Row: ",
                        err
                        );
                    // The check is considered failed.
                    // Update the value of the checkRowResult const
                    checkRowResult.value = false;

                    // Increment the Failed counter
                    iFailed.value = (iFailed.value + 1);
                    // Stop here
                    return;
                }
                // Log for debugging:
                console.log("After the try, the checkRowResult for the Row: ",
                    i.value,
                    " is: ",
                    checkRowResult.value
                    );
                // What is the result of the check?
                if (checkRowResult.value !== true) {
                    // Log for debugging:
                    console.log("Row: ",
                        i.value,
                        " did not pass the row Checks. We are not trying to write it."
                        );
                    // Increment the Failed counter
                    iFailed.value = (iFailed.value + 1);
                    // Stop here
                    return;
                }
                // The data in the row looks OK, prepare the API call
                // Build the Call to the endpoint
                const apiPrefix = process.env.VUE_APP_API_FIREBASE_BACKEND;
                const endPoint = (apiPrefix +
                    "/insert/move-voucher"
                );
                // Log for debugging
                console.log("The endpoint is: "
                    , endPoint
                );

                // Axios interceptor to add 
                //  - user credentials
                //  - FireBase User ID
                axios.interceptors.request.use(
                    function (req) {
                        // log for debugging
                        //                console.log("Interceptor returns: ",
                        //                    "- Method: ", 
                        //                    req.method,
                        //                    "- url: ", 
                        //                    req.url
                        //                    );
                        // Add the FireBase Token to the API request
                        req.headers["Authorization"] = "Bearer " + userToken;
                        return req;
                    }
                );
                // We have everything
                // Try to send the row to the database
                try {
                    // Stringify the data
                    const reqData = qs.stringify(result.data);
                    // The Axios Options:
                    const axiosOptions = {
                        method: "POST",
                        url: endPoint,
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        data: reqData,
                    }
                    // We make the call to the endpoint
                    const response = await axios(axiosOptions);
                    
                    // Log for debugging
                    console.log("We processed row: ",
                        i.value,
                        " The reponse from the API was: ",
                        response.data
                    );

                    // Increment the success counter
                    iSuccess.value = (iSuccess.value + 1);

                } catch (err) {
                    // There was an error
                    // Log for debugging
                    console.log("Error trying to call the API: ",
                        " for row: ",
                        i.value,
                        " The error was: ",
                        err.value
                    );
                    // Increment the Failed counter
                    iFailed.value = (iFailed.value + 1);
                }
            };

            // We prepare the parameters of the function to parse the file:
            const papaparseConfig = ({
                // The 1st line contains the headers
                header: true,
                // FOR DEV ONLY - limit the parsing to the 1st row only
                //preview: 1,
                // END FOR DEV ONLY
                // Ignore empty line or lines with empty values
                skipEmptyLines: "greedy",
                // For each row, the Step function will 
                //  - Read the content of the row and place it in the `result` variable
                //  - Call the `parser` function
                step: function (result, parser) {
                    // Increment the counter
                    i.value = (i.value + 1);

                    // Log for debugging
                    console.log("We are processing row:"
                        , i.value
                    );
                    //console.log("Row data:"
                    //    , result.data
                    //);
                    console.log("Row errors:"
                        , result.errors
                    );

                    // Pass the data to the parser
                    parser = writeToVault(result);
                    // TEMP while building the `checkRow` function
                    //parser = checkRow(result);
                },
                // After each row has been processed we do this
                complete: function () {
                    // Log for debugging
                    console.log("All done!",
                        " We processed: ",
                        i.value,
                        " rows"
                        );

                    // Track if we are currently waiting
                    isPending.value = false;
                    success.value = true;
                },
            });

            // We parse the file.
            await Papa.parse(fileSelected, papaparseConfig);

        }

        return {
            handleInsert,
            nextPage,
            tryAgain,
            file,
            i, 
            iSuccess,
            iFailed, 
            isPending,
            isInitial,
            success,
            error
        }
    }
}

</script>
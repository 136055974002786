// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFunctions } from "firebase/functions";
// Import the functions needed for App check
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_KEY_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize authentication service
const firebaseAuth = getAuth(app)

// Initialize Analytics
const analytics = getAnalytics(app);

// Initialize the Functions
const functions = getFunctions(app);

// Initialize AppCheck
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.

// We use the Public RECAPTCHA key in the `.env` file
const recaptchaPublicKey = process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY;

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(recaptchaPublicKey!),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

export { analytics, firebaseAuth, functions, appCheck };

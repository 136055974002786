<template>
  <div id="header">
    <div class="container">
      <header class="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
        <MainNavbar />
      </header>
    </div>
  </div>
  <div class="content">
    <router-view/>
  </div>
</template>

<script>
// We use the Navbar Component on all pages
import MainNavbar from "@/components/MainNavbar.vue";

// We create the object we need in the template
export default {
  components: { MainNavbar },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>

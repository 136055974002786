import { ref } from 'vue'
// We are using the FireBase authentication
import { firebaseAuth } from '@/firebase/config'
// The signout function
import { signOut } from 'firebase/auth'

// make sure the error is initially set to Null
const error = ref('')
// Set the initial value for this
const isPending = ref(false)

// logout function
const logout = async () => {
  // Reset error value if there was a previous error
  error.value = ''
  // We are waiting for an answer
  isPending.value = true

  try {
    // Try to sign the user out
    await signOut(firebaseAuth)
    // If it goes through:
    // Record that there was no error
    error.value = ''
    // We are no longer waiting
    isPending.value = false
    // Return the login information
  }
  // If there is an error - Record the error message 
  catch(err) {
    // Display the error in the Console
    console.log(err.message)
    // We are no longer waiting
    isPending.value = false
    // Update the error message that was set to NULL earlier
    error.value = err.message;
  }
}

// We record the information for other components
const useLogout = () => {
  return { error, logout, isPending }
}

export default useLogout
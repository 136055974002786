<template>
    <div class="Upload">
        <div>
            <h1>Step 1- Upload MOVE Vouchers</h1>
            <p>Upload the csv file generated from MOVE to the MOVE_IN GCP Bucket</p>
            <a 
            href="https://uniqgift.sharepoint.com/sites/VoucherApp/SitePages/Prepare-Import-File.aspx" 
            target="_blank"
            >How to Prepare the import file (.csv) (On UG SharePoint)</a>
        </div>
        <div class="dropbox">
            <input 
            ref="file" 
            v-on:change="handleFileUpload()"  
            type="file"
            accept="text/csv"
            single
            class="input-file"
            >
        <p v-if="isInitial">Drag your file here to begin<br> or click to browse</p>
        <p v-if="isPending">Uploading file...</p>
        <p v-if="success">The file has been uploaded</p>
        </div>
    </div>
</template>

<script>

import { ref } from "vue";
// Import axios to call API endpoints
import { post, interceptors } from 'axios';
// Who is the currently logged in user
import { firebaseAuth } from '@/firebase/config';
// We also need the `useRouter`
//import { useRouter } from 'vue-router'

export default{
    name:'Upload MOVE order file',
    setup() {
        // Create a const to return errors if we have any
        const error = ref(null);

        // file to upload is empty at first
        const file = ref(null)

        // Track if we are in the initial state
        const isInitial = ref(true);

        // Track if we are currently waiting
        const isPending = ref(false);

        // Track if we have results from the function
        const success = ref(false);

        // We need this to send the user to the next page
        //const router = useRouter()

        const handleFileUpload = async() => {
            // debugger;
            console.log("selected file",file.value.files[0])
            //Upload to server
            // We are currently waiting
            isPending.value = true;

            // TODO: Refactor to get the information from the `getUser` composable
            const userToken = firebaseAuth.currentUser.accessToken;
            const userId = firebaseAuth.currentUser.uid;

            // Get the infor about the selected file
            const fileSelected = file.value.files[0];

            // debugger
            //console.log("Info about the file selected: ", fileSelected);
            // Build the Call to the endpoint
            const apiPrefix = process.env.VUE_APP_API_FIREBASE_BACKEND;
            const endpoint = (apiPrefix + 
                "/upload/orders/move-export"
                );
            // debugger
            console.log("The endpoint is: ", endpoint);

            // Axios interceptor to add 
            //  - user credentials
            //  - FireBase User ID
            interceptors.request.use(
              function (req) {
                  // log for debugging
    //                console.log("Interceptor returns: ",
    //                    "- Method: ", 
    //                    req.method,
    //                    "- url: ", 
    //                    req.url
    //                    );
                  // Add the FireBase Token to the API request
                  req.headers["Authorization"] = "Bearer " + userToken;
              return req;
              }
            );

            // Prepare the form-data
            let formData = new FormData();
            formData.append("file", fileSelected);
            
            // debugger
            console.log("The form-data is:", formData);
            // Try to upload the file
            try {
                const uploadFile = await post(endpoint, 
                    formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                    });

                // Capture the result of the call we made
                const uploadFileResult = uploadFile.data;
                console.log("The response is: ", uploadFileResult);

                // We got a result:
                isPending.value = false;
                success.value = true;
                error.value = '';

                // We redirect to the `Activate` page
                //router.push({ name: 'Generate And Activate Generic eCodes' });

            } catch (error) {
                // We update the value of the error const
                error.value = error.message
                // Log the error
                console.log("The error is: ", error.value);
                // We got a result:
                isPending.value = false;
                success.value = false; 
            } 
        }

        return {
          handleFileUpload,
          file,
          isPending, 
          isInitial, 
          success,
          error
       }
    }
}

</script>
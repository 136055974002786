import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
// Styling
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import BootstrapVue3 from 'bootstrap-vue-3';
// firebase imports
import { firebaseAuth } from './firebase/config'
import { onAuthStateChanged } from 'firebase/auth'

// Optional, since every component import their Bootstrap functionality
// the following line is not necessary
// import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';

// Global Styles
import '@/assets/main.css'

// Define a variable to record the state of the app
let app:any;

// Avoid displaying the content created for non logged user on refresh
// Check the user state
onAuthStateChanged(firebaseAuth, () => {
    // If the `app` variable is not mounted already
    // (`app` is NULL)
    if (!app) {
        // Mount the app
        app = createApp(App);
        app.use(store);
        app.use(router);
        app.use(ElementPlus);
        app.use(BootstrapVue3);
        app.mount('#app');
    }
});

<template>
  <div class="Download">
    <h1>Step 3- Download eCodes</h1>
    <p>Enter the Order Number to Download the eCodes.</p>
    <div v-if="error" class="error">There was an error <b>{{ error }}</b></div>
    <div v-if="isPending">Preparing File...</div>
    <div v-if="apiStatusSuccess">File has been downloaded for Order Number: <b>{{ orderNumber }}</b></div>
    <form @submit.prevent>
      <label>Order Number:</label>
      <input type="text" placeholder="Enter your Order Number" v-model="orderNumber" required>
      <div class="submitButton">
        <el-button 
          type="primary" 
          round @click="handleSubmit"
          label="downloaded_ecodes.csv" >Download eCodes</el-button>
      </div>
    </form>
    <div v-if="gotResult">We got a result from the submission...</div>
    <div v-if="apiStatusSuccess">The API returned a success result...</div>
  </div>
</template>

<script>
// Other Imports
import { ref } from 'vue';
// Import axios to call API endpoints
import { get, interceptors } from 'axios';
// Who is the currently logged in user
import { firebaseAuth } from '@/firebase/config';

export default {
  name: "Download eCodes",
  components: {},
  setup() {
    // The orderNumber is empty at first
    const orderNumber = ref('');

    // The API response is empty at first
    const apiResponse = ref('');

    // Create a const to return errors if we have any
    const error = ref(null);
    
    // Track if we are currently waiting
    const isPending = ref(false);
    
    // Track if we have results from the handleSubmit
    const gotResult = ref(false);

    // Track if we have results from the function
    const apiStatusSuccess = ref(false);

    // The default file name we assign to the file.
    const label = 'downloaded_ecodes.csv';

    // Function when we submit the request
    const handleSubmit = async () => {
      // We are currently waiting
      isPending.value = true;

      // TODO: Refactor to get the information from the `getUser` composable
      const userToken = firebaseAuth.currentUser.accessToken;
      const userId = firebaseAuth.currentUser.uid;

      // Throw an error if the Order Number has the default value
      if (orderNumber.value == "") {
        // We can't get a result:
        isPending.value = false;
        gotResult.value = true;
        // We throw an error
        throw Error ("enter your orderNumber");
      } else {
        // Try to get the result from the function
        try {
          // Emtpy variables in case we call this again
          apiResponse.value = null;
          gotResult.value = false;
          apiStatusSuccess.value = false;
          error.value = false;

          // Build the Call to the endpoint
          const apiPrefix = process.env.VUE_APP_API_FIREBASE_BACKEND;
          // Add the orderNumber
          const endpoint = (apiPrefix + 
            "/download-ecodes" + 
            "?userId=" +
            userId +
            "&orderNumber=" + 
            orderNumber.value
            );
          
          // Lof for debugging
          console.log("The endpoint is: ", endpoint);

          // Axios interceptor to add 
          //  - user credentials
          //  - FireBase User ID
          interceptors.request.use(
            function (req) {
                // log for debugging
    //              console.log("Interceptor returns: ",
    //                  "- Method: ", 
    //                  req.method,
    //                  "- url: ", 
    //                  req.url
    //                  );
                // Add the FireBase Token to the API request
                req.headers["Authorization"] = "Bearer " + userToken;
            return req;
            }
          );
          // We start waiting
          isPending.value = true

          // Try to call the API
          const docRef = await get(endpoint);
          // All this is needed to open a download window on the browser
          // Create a blob with the content of the file.
          const blob = new Blob([docRef.data], { type: "text/csv" });
          // Log for debugging
          console.log("The blob we got is: "
            , blob
            );
          // Create a temporary link
          const link = document.createElement("a");
          // Define the resource attached to the link
          link.href = URL.createObjectURL(blob);
          // Label
          link.download = label;
          // Auto Click on the link <-- this call the download functionality in the browser
          link.click();
          // Cleanup the link
          URL.revokeObjectURL(link.href);
          
          // We got a result:
          isPending.value = false;
          gotResult.value = true;
          apiStatusSuccess.value = true;

          // Update the error messages
          error.value = null;

          // Capture the result of the API call
          apiResponse.value = ({"message": "We got a reply from the API",
          });

        // If there is an error - catch it
        } catch (err) {
          // We update the value of the error const
          error.value = err.message;
          console.log("Error trying to call the API: ", 
            error.value
            );
          // We got a result:
          isPending.value = false;
          gotResult.value = true;
          apiStatusSuccess.value = false;
          apiResponse.value = err.message;
        }
      }
    }
    // Return so we can use these in the template
    return {  
      orderNumber, 
      error, 
      isPending, 
      gotResult, 
      apiStatusSuccess, 
      apiResponse, 
      handleSubmit, 
      label,
    }
  }
};

</script>

<style lang="scss">
  form {
    max-width: 420px;
    margin: 30px auto;
    background: white;
    text-align: left;
    padding: 40px;
    border-radius: 10px;
  }
  label {
    color: #aaa;
    display: inline-block;
    margin: 25px 0 15px;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
  }
  input {
    display: block;
    padding: 10px 6px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ddd;
    color: #555;
  }
  .submitButton {
    padding: 20px 6px;
    display: block;
    text-align: center;
  }

  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }
  
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }  
</style>